import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  faBatteryHalf,
  faServer,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AppContext } from '../../context/appContext';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import SyncHandler from '../../controller/syncHandler/SyncHandler';
import { fetchApi } from '../../AjaxUtil';
import Configs from '../../Configs';
import PairScannerAuth from '../../controller/authHandler/PairScannerAuthHandler';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import ScannerDataHandler from '../../controller/scannerHandler/ScannerHandler'
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import * as constants from '../../constants/constants';
import { useErrorHanlder } from '../../context/errorContext';

const HealthCheck = function ({ anchorEl, open, handleClose }) {
  const { connection } = useContext(AppContext);
  const [wifi, setWifi] = useState({ error: null });
  const [server, setServer] = useState({ error: null });
  const [batttery, setBattery] = useState({ level: 0, charging: true });
  /* const [isSyncComplete, setIsSyncComplete] = useState(false); */
  const { showErrorNotification } = useErrorHanlder()
  let pruneBarcodes = [];

  useEffect(() => {
    let battery;
    let healthCheckInterval;
    try {
      navigator.getBattery().then((batteryResponse) => {
        battery = batteryResponse;
        battery.addEventListener('levelchange', updateBattery);
        updateBattery(battery.level);
        // console.log('Battery Level:', battery.level);
      });
    } catch (batteryErr) {
      // console.log('Battery: no data');
    }

    checkWifiConnection();

    if (PairScannerNameHandler.getScannerName() !== undefined && ScannerUserHandler.getScannerUser() !== undefined) {
      healthCheckInterval = setInterval(() => {
        checkServerConnection();
        fetchOfflineBatchAPI();
        /* ( if (connection && offlineBatchData) checkIsSyncComplete(); */
      }, Configs.HEALTHCHECK.TRIGGER_DURATION);
    }

    return () => {
      if (battery) {
        battery.removeEventListener('levelchange', updateBattery);
      }
      clearInterval(healthCheckInterval);
    };
  }, [
    batttery.level,
    connection,
    PairScannerNameHandler.getScannerName(),
    ScannerUserHandler.getScannerUser(),
  ]);

  const updateBattery = (percentage) => {
    setBattery({ level: parseInt(percentage * 100, 10) });
  };

  /* function checkIsSyncComplete() {
    if (offlineBatchData) {
      const offlineTempData = JSON.parse(offlineBatchData);
      let tempSyncComplete = true;

      offlineTempData.forEach((data) => {
        const dataSynced = data.synced;
        if (dataSynced === false) tempSyncComplete = false;
      });
      setIsSyncComplete(tempSyncComplete);
    }
  } */

  async function fetchOfflineBatchAPI() {
    const offlineBarcodes = OfflineBatchHandler.getOfflineBatchDataObj();

    let unSyncedBarcodes = [];
    for (const barcode of offlineBarcodes) {
      if (!barcode.synced) {
        if (barcode.recordId === undefined || barcode.barcode === undefined || barcode.admissionType === undefined
            || barcode.gateId === undefined || barcode.scanResult === undefined || barcode.scanDate === undefined
            || barcode.scanBy === undefined || barcode.scanCheck === undefined || barcode.scanMode === undefined
            || barcode.scannerProfile === undefined) {
          // Skip
        } else {
          unSyncedBarcodes.push(barcode);
        }
      }
    }

    if (unSyncedBarcodes.length > 0) {
      pruneBarcodes = [];
      pruneBarcodes.push(...unSyncedBarcodes);
    const param = {
        offlineBatch: unSyncedBarcodes,
    };
    await fetchApi(
      Configs.HEALTHCHECK.OFFLINE_SYNC_API_METHOD,
      Configs.HEALTHCHECK.OFFLINE_SYNC_API_PATH,
      onSuccessCallbackOffline,
      param,
      onErrorCallbackOffline,
    );
    }
  }

  const onSuccessCallbackOffline = (response) => {
    // handle success
    //console.log('HEALTHCHECK sync succ', response, status);
    //console.log('>>>>>>pruneBarcodes: ' + pruneBarcodes.length);

    // Pick from fresh before update
    let offlineBatchData = OfflineBatchHandler.getOfflineBatchData();

    const failedSync = response.failedSync;
    let offlineTempData = JSON.parse(offlineBatchData) || [];

      // update offlineBatchData sync info
      if (failedSync.length !== 0) {
        // sync fail
      Object.keys(offlineTempData).forEach((obj, index) => {
        Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
          let isMatchRecordId = false;
        failedSync.forEach((data) => {
            if (data === offlineTempData[index].recordId) {
              isMatchRecordId = true;
            }
          });
            const isSyncFalse = offlineTempData[index].synced === false;
          if (!isMatchRecordId && isSyncFalse && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
              offlineTempData[index].synced = true;
            }
          });
        });
      } else {
        // sync complete
        Object.keys(offlineTempData).forEach((obj, index) => {
        Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
          const isSyncFalse = offlineTempData[index].synced === false;
          if (isSyncFalse && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
            //console.log("...updated" + pruneBarcodes[preindex].recordId );
          offlineTempData[index].synced = true;
          }
        });
        });
      }
      OfflineBatchHandler.setOfflineBatchData(offlineTempData);
    /* offlineTempData = JSON.stringify(offlineTempData);
    setOfflineBatchData(offlineTempData); */
  };

  const onErrorCallbackOffline = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err})
  };

  const onSuccessCallback = () => {
    // const onSuccessCallback = (response, status) => {
    // handle success
    // console.log('succ', response, status);
    setServer({ error: false });
  };

  const onErrorCallback = (err, status, message) => {
    setServer({ error: true });
    showErrorNotification({message: `${status}: ${message}` || err})
  };

  const checkWifiConnection = () => {
    if (connection) {
      setWifi({ error: false });
    } else {
      AppModeHandler.setAppMode(constants.AppModeOffline);
      setWifi({ error: true });
    }
  };

  const checkServerConnection = async () => {
    // call api
    let param = null;
    if (batttery) {
      const { serverConfig } = JSON.parse(ScannerDataHandler.getScannerData());
      const { primaryServer, secondaryServer } = serverConfig || {};

      param = {
        scannerProfileId: PairScannerAuth.getScannerInfo(),
        batteryStatus: batttery.level,
        connectivityStatus: connection ? 5 : 1,
        message: '',
        lastConfigFetch: SyncHandler.getLastConfigFetchDate(),
        currentServer: primaryServer ? 'primary' : 'secondary',
        currentServerMode: primaryServer?.type || secondaryServer?.type || 'cloud',
        mode: AppModeHandler.appMode().toLowerCase(),
      };
    }

    await fetchApi(
      Configs.HEALTHCHECK.API_METHOD,
      Configs.HEALTHCHECK.API_PATH,
      onSuccessCallback,
      param,
      onErrorCallback,
    );
  };

  return (
    <Menu
      id='menu-appbar'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <MenuItem>
        <ListItemIcon>
          <FontAwesomeIcon
            icon={faWifi}
            color={wifi.error ? '#BF0603' : '#FCA311'}
          />
        </ListItemIcon>
        <ListItemText primary='Wifi' />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <FontAwesomeIcon
            icon={faServer}
            color={server.error ? '#BF0603' : '#FCA311'}
          />
        </ListItemIcon>
        <ListItemText primary='Server' />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <FontAwesomeIcon icon={faBatteryHalf} color='#FCA311' />
        </ListItemIcon>
        <ListItemText primary={`${batttery.level} %`} />
      </MenuItem>
    </Menu>
  );
};

HealthCheck.propTypes = {
  anchorEl: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

HealthCheck.defaultProps = {
  anchorEl: null,
  handleClose: null,
};

export default HealthCheck;