import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import ToastMessage from '../resultDisplay/ToastMessage';
import SyncHandler from '../../controller/syncHandler/SyncHandler';
import EventDataHandler from '../../controller/eventHandler/EventHandler';
import GateDataHandler from '../../controller/eventHandler/GateHandler';
import PairScannerAuth from '../../controller/authHandler/PairScannerAuthHandler';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import ScannerDataHandler from "../../controller/scannerHandler/ScannerHandler";
import Configs from '../../Configs';
import { fetchApi } from '../../AjaxUtil';
import successImg from '../../assets/images/success.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/offlineBatch.css';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import * as constants from '../../constants/constants';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import Auth from '../../controller/authHandler/AuthHandler';
import ValidatorModeHandler from '../../controller/validatorModeHandler/ValidatorModeHandler';
import VoucherModeHandler from '../../controller/voucherModeHandler/voucherModeHandler';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import { useErrorHanlder } from '../../context/errorContext';

const SyncBarcode = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const { setOnLoadSync, connection, setConnection, scannerInfo, setIsSingleEventGate, 
    setAppMode,
    setValidatorMode,
    setVoucherMode,
    setToken,
    setScannerInfo
   } = useContext(AppContext);
  const [progressPercent, setProgressPercent] = useState(0);
  // const timer = () => setProgressPercent(progressPercent + 1);
  const [calcPercent, setCalcPercent] = useState(0);
  const [timeLeft, setTimeLeft] = useState(Configs.SYNC.TOTAL_SYNC_DURATION);
  const [responseSucc, setResponseSucc] = useState(false);
  const [responseFail, setResponseFail] = useState(false);
  const [syncAlertMsg, setSyncAlertMsg] = useState();
  const [syncAlertStatus, setSyncAlertStatus] = useState();
  const [isShowMsg, setIsShowMsg] = useState();
  const [isSyncComplete, setIsSyncComplete] = useState(false);
  const location = useLocation();
  const [isMultipleEvent, setIsMultipleEvent] = useState(false);
  const [isMultipleGate, setIsMultipleGate] = useState(false);
  const [scannerName] = useState(PairScannerNameHandler.getScannerName());
  const [isRunComplete, setIsRunComplete] = useState(false);
  const [currentGate, setCurrentGate] = useState();
  const [offlineSyncComplete, setOfflineSyncComplete] = useState(false);
  const offlineBatchData = OfflineBatchHandler.getOfflineBatchDataObj();
  const { showErrorNotification } = useErrorHanlder()

  useEffect(() => {
    if (!connection) {
      setProgressPercent(progressPercent);
      setCalcPercent(calcPercent);
      setResponseFail(true);
    }
  }, [connection]);

  useEffect(() => {
    // console.log('isSyncComplete', isSyncComplete);
    if (isSyncComplete && connection) setProgressPercent(100);
  }, [isSyncComplete]);

  useEffect(() => {
    if (responseFail) {
      fetchSyncBarcodeApi();
      setIsShowMsg(true);
      setSyncAlertStatus('fail sync');
      setSyncAlertMsg(t(Configs.SYNC.FAIL_MSG));
      setOnLoadSync(true);
      setTimeout(
        () => history.push(Configs.SCAN.PATH),
        Configs.SYNC.FAIL_DISPLAY_DURATION
      );
    } else {
      setSyncAlertMsg();
    }
  }, [responseFail]);

  useEffect(() => {
    if (timeLeft === 0) {
      // console.log('TIME LEFT IS 0');
      setTimeLeft(null);
      // if slow network & calcPercent != 100 in preset duration
      if (calcPercent !== 100 && progressPercent === 100 && connection) {
        setCalcPercent(100);
      }
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    if (connection) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 50);
        // console.log('timeLeft----->',timeLeft);
        timerCalPercent();
      }, 50);

      // clear interval on re-render to avoid memory leaks
      return () => {
        clearInterval(intervalId);
      };
    }
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, calcPercent]);

  useEffect(() => {
    if (connection) {
      if (calcPercent === 100) {
        setIsShowMsg(true);
        setSyncAlertStatus('success sync');
        setSyncAlertMsg(t(Configs.SYNC.SUCCESS_MSG));
        setOnLoadSync(true);
        document
          .querySelector('#syncProfile-progress-percent')
          .classList.add('right');
        if (!currentGate?._id && (isMultipleEvent || isMultipleGate)) {
          setTimeout(
            () => history.push(Configs.EVENT.PATH, { from: location.pathname }),
            Configs.SYNC.SUCCESS_DISPLAY_DURATION
          );
        } else if (
          EventDataHandler.getEventInfo() &&
          GateDataHandler.getGateInfo()
        ) {
          setTimeout(
            () => history.push(Configs.SCAN.PATH),
            Configs.SYNC.SUCCESS_DISPLAY_DURATION
          );
        }
      }
    }
  }, [calcPercent, isRunComplete]);

  useEffect(() => {
    if (connection) {
      if (progressPercent === 0) {
        // Step 1 - check connection - 0-25%
        setProgressPercent(25);
      } else if (progressPercent === 25) {
        // Step 2 - sync data - 25-50%
        fetchSyncBarcodeApi();
      } else if (progressPercent === 75) {
        // Step 3 - split data - 50-75%
        setIsSyncComplete(true);
      } else if (progressPercent === 100) {
        // display frontend % > calcPercent
      }
    } else {
      setProgressPercent(progressPercent);
    }
  }, [progressPercent, responseSucc]);

  const timerCalPercent = () => {
    // console.log('progressPercent----->', progressPercent);
    // console.log('calcPercent----->', calcPercent);
    if (progressPercent !== calcPercent) {
      let currentP = calcPercent + 2;
      if (currentP > 100) {
        currentP = 100;
      }
      setCalcPercent(currentP);
    }
  };

  const onSuccessCallback = (response) => {
    // handle success
    // console.log('sync succ', response, status);
    setProgressPercent(30); // to prevent double API call
    setResponseSucc(true);
    response?.forEach((event) => {
      event?.admissionRules?.forEach((admissionRule) => {
        admissionRule?.admissionRuleGate?.forEach((ruleGate) => {
          ruleGate?.gates?.forEach((gate) => {
            if (gate?._id === GateDataHandler.getGateInfo())
              setCurrentGate(gate);
          });
        });
      });
    });

    // set sync data in local storage
    // - ALL
    SyncHandler.setSyncData(response);
    SyncHandler.setLastConfigFetchDate(new Date().toISOString())
    if (SyncHandler.getSyncData()) setProgressPercent(50);
    // - SINGLE event / session code
    Object.keys(response).forEach((obj, index) => {
      // console.log('EVENTS', response[index], obj, index);
      localStorage.setItem(
        `EVENT_${response[index].eventCode}`,
        JSON.stringify(response[index])
      );
      Object.keys(response[index].sessions).forEach((obj2, index2) => {
        // console.log('SESSIONS', response[index].sessions[index2], obj2, index2);
        localStorage.setItem(
          `SESSION_${response[index].sessions[index2].sessionCode}`,
          JSON.stringify(response[index].sessions[index2])
        );
      });
    });

    if (response.length > 1) {
      setIsMultipleEvent(true);
      setIsSingleEventGate(false);
      setProgressPercent(75);
    } else {
      // only 1 event
      let gateCount = 0;
      let gateLength = 0;

      Object.keys(response[0].admissionRules).forEach((obj, index) => {
        gateLength = response[0].admissionRules[index].admissionRuleGate.length;
        gateCount += gateLength;
      });

      if (gateCount > 1) {
        setIsMultipleGate(true);
        setIsSingleEventGate(false);
      } else {
        // only 1 gate
        setIsSingleEventGate(true);
        const eventId = response[0]._id;
        const { eventName } = response[0];
        let gateId;
        let gateName;
        let firstItem = response[0];
        if (firstItem) {
          for (let x = 0; x < firstItem.admissionRules.length; x++) {
            if (firstItem.admissionRules[x].admissionRuleGate.length > 0) {
              gateId =
                firstItem.admissionRules[x].admissionRuleGate[0].gateTypeId;
              gateName =
                firstItem.admissionRules[x].admissionRuleGate[0].gateName;
              break;
            }
          }
        }
        EventDataHandler.setEventInfo(eventId);
        EventDataHandler.setEventNameInfo(eventName);
        GateDataHandler.setGateInfo(gateId);
        GateDataHandler.setGateNameInfo(gateName);
      }
      setProgressPercent(75);
    }

    setIsRunComplete(true);
  };

  /** start unpair */
  const onSuccessCallbackUnpair = () => {
    SyncHandler.removeSyncData();
    SyncHandler.removeLastConfigFetchDate();
    OfflineBatchHandler.removeOfflineBatchData();
    PairScannerAuth.removeScannerInfo();
    PairScannerAuth.removeScannerToken();
    PairScannerNameHandler.removeScannerName();
    ScannerDataHandler.removeScannerData();
    Auth.removeSecret();
    Auth.removeToken();
    setScannerInfo(null);
  };
  const onErrorCallbackUnpair = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
  };
  const goToPairScanner = () => {
    try {
      const scannerProfileId = PairScannerAuth.getScannerInfo();
      Configs.PAIRSCANNER.UNPAIR_API_PATH = `scannerProfile/${scannerProfileId}/unpair/`;
      const paramProfile = {
        scannerProfileId: scannerInfo,
      };
      fetchApi(
        Configs.PAIRSCANNER.UNPAIR_API_METHOD,
        Configs.PAIRSCANNER.UNPAIR_API_PATH,
        onSuccessCallbackUnpair,
        paramProfile,
        onErrorCallbackUnpair
      );
    } catch (err) {
      console.log('rocket 11', err);
    }
  };
  /** end unpair */

  /** start offline sync */
  let pruneBarcodes = [];
  const onSuccessCallbackOffline = (response) => {
    if (offlineSyncComplete === false) {
      const failedSync = response.failedSync;
      let offlineTempData = offlineBatchData;

      // update offlineBatchData sync info
      if (failedSync.length !== 0) {
        // sync fail
        Object.keys(offlineTempData).forEach((obj, index) => {
          Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
            let isMatchRecordId = false;
            failedSync.forEach((data) => {
              if (data === offlineTempData[index].recordId) {
                isMatchRecordId = true;
              }
            });
            const isSyncFalse = offlineTempData[index].synced === false;
            if (
              !isMatchRecordId &&
              isSyncFalse &&
              pruneBarcodes[preindex].recordId ==
                offlineTempData[index].recordId
            ) {
              offlineTempData[index].synced = true;
            }
          });
        });
        setOfflineSyncComplete(false);
      } else {
        // sync complete
        Object.keys(offlineTempData).forEach((obj, index) => {
          Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
            const isSyncFalse = offlineTempData[index].synced === false;
            if (
              isSyncFalse &&
              pruneBarcodes[preindex].recordId ==
                offlineTempData[index].recordId
            ) {
              //console.log('...updated' + pruneBarcodes[preindex].recordId );
              offlineTempData[index].synced = true;
            }
          });
        });
        setOfflineSyncComplete(true);
      }
      OfflineBatchHandler.setOfflineBatchData(offlineTempData);
      goToPairScanner();
    }
  };
  const onErrorCallbackOffline = (err, status, message) => {
    goToPairScanner()
    showErrorNotification({message: `${status}: ${message}` || err});
  };
  const unpairHandler = () => {
    if (offlineSyncComplete === true) {
      goToPairScanner();
    } else if (offlineBatchData) {
      let unSyncedBarcodes = [];
      for (const barcode of offlineBatchData) {
        if (!barcode.synced) {
          if (
            barcode.recordId === undefined ||
            barcode.barcode === undefined ||
            barcode.admissionType === undefined ||
            barcode.gateId === undefined ||
            barcode.scanResult === undefined ||
            barcode.scanDate === undefined ||
            barcode.scanBy === undefined ||
            barcode.scanCheck === undefined ||
            barcode.scanMode === undefined ||
            barcode.scannerProfile === undefined
          ) {
            // Skip
          } else {
            unSyncedBarcodes.push(barcode);
          }
        }
      }

      if (unSyncedBarcodes.length > 0) {
        pruneBarcodes = [];
        pruneBarcodes.push(...unSyncedBarcodes);

        const param = {
          offlineBatch: unSyncedBarcodes,
        };
        fetchApi(
          Configs.HEALTHCHECK.OFFLINE_SYNC_API_METHOD,
          Configs.HEALTHCHECK.OFFLINE_SYNC_API_PATH,
          onSuccessCallbackOffline,
          param,
          onErrorCallbackOffline
        );
      } else {
        goToPairScanner();
      }
    } else {
      // x connection
      // console.log('no connection...');
    }
  };
  const clearStoredData = () => {
    try {
      setConnection(true);
      Auth.setToken(constants.GuestJWT(Auth.getSecret()));
      GateDataHandler.removeGateInfo();
      GateDataHandler.removeGateNameInfo();
      EventDataHandler.removeEventInfo();
      EventDataHandler.removeEventNameInfo();
      AppModeHandler.removeAppMode();
      ValidatorModeHandler.removeValidatorMode();
      VoucherModeHandler.removeVoucherMode();
      ScannerUserHandler.removeScannerUser();
      ScanInHandler.setIsScanInInfo(true);
      let keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (key.includes('EVENT_') || key.includes('SESSION_')) {
          localStorage.removeItem(key);
        }
      });
      setAppMode(false);
      setValidatorMode(false);
      setVoucherMode(false);
      setToken(null);
      unpairHandler();
    } catch (err) {
      Auth.removeToken();
      setToken(null);
    }
  };
  /** end offline sync */

  const onErrorCallback = (err, status, message) => {
    setSyncAlertStatus('fail sync');
    setIsShowMsg(true);
    setOnLoadSync(true);
    if (message === t('pairScanner_fail_auth_token')) {
      setSyncAlertMsg(t('pairScanner_fail_auth_token'));
      SyncHandler.removeSyncData();
      SyncHandler.removeLastConfigFetchDate();
      GateDataHandler.removeGateInfo();
      GateDataHandler.removeGateNameInfo();
      EventDataHandler.removeEventInfo();
      EventDataHandler.removeEventNameInfo();
      clearStoredData();
    } else {
      setConnection(false);
    }
    AppModeHandler.setAppMode(constants.AppModeOffline);
    setIsRunComplete(true);
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  async function fetchSyncBarcodeApi() {
    let keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.includes('EVENT_') || key.includes('SESSION_')) {
        localStorage.removeItem(key);
      }
    });
    const scannerProfileId = PairScannerAuth.getScannerInfo();
    Configs.SYNC.API_PATH = `scannerProfile/${scannerProfileId}/sync/`;
    const params = {
      token: PairScannerAuth.getScannerToken(),
    };
    await fetchApi(
      Configs.SYNC.API_METHOD,
      Configs.SYNC.API_PATH,
      onSuccessCallback,
      params,
      onErrorCallback
    );
  }

  return (
    <div className='syncProfile-form'>
      <div className='syncProfile-container'>
        <div className='sync-msg-box'>
          <img src={successImg} alt='logo' className='scan-success-img' />
          <div className='syncProfile-details'>
            {' '}
            {t('sync_pairedText_1')} {`${scannerName}`} {t('sync_pairedText_2')}
          </div>
        </div>
        <div className='syncProfile-form-box'>
          <p className='syncProfile-form-title'>{t('sync_title')}</p>
          <p className='syncProfile-form-desc'>{t('sync_desc')}</p>
          <ProgressBar now={calcPercent} />
          <p
            className='syncProfile-progress-percent'
            id='syncProfile-progress-percent'
          >
            {calcPercent}
            {t('sync_percentCompleteText')}
          </p>
        </div>
      </div>
      {isShowMsg && <ToastMessage cName={syncAlertStatus} msg={syncAlertMsg} />}
    </div>
  );
};

export default SyncBarcode;
