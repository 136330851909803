import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import * as constants from '../../constants/constants';
import '../../styles/resultDisplay.css';
import VoucherModeHandler from '../../controller/voucherModeHandler/voucherModeHandler';

const ResultDisplay = function ({ cName, additionalMsg, onClick, style, setIsShowResult,isManualEntry, setScanCount, setShowPopup }) {
  const { t } = useTranslation();
  const { barcode, reason } = useContext(AppContext);
  const [admissionMsg, setAdmissionMsg] = useState('');
  const [iconClass, setIconClass] = useState(faCheckCircle);
  const [isScanIn] = useState(ScanInHandler.getIsScanInInfo());
  const [voucherMode] = useState(VoucherModeHandler.voucherMode());

  useEffect(() => {
    if (barcode.scanResult === 1 || reason) {
      // || [constants.AppModeOffline, constants.AppModeEmergency].includes(appMode)) {
      if (barcode.scanCheck === constants.ScanCheckTrue) {
        setIconClass(faExclamationCircle);
      } else {
        setIconClass(faCheckCircle);
      }
      if (isScanIn === 'true') {
        setAdmissionMsg(
          `${t('resultDisplay_entryText')}\n${
            barcode.scanReason !== null ? barcode.scanReason : 'Success'
          }`,
        );
      } else {
        // exit
        setAdmissionMsg(`${t('resultDisplay_exitText')}`);
      }
    } else {
      // x valid - TODO
      setIconClass(faTimesCircle);
      if (isScanIn === 'true') {
        setAdmissionMsg(
          `${barcode.scanMessage}\n${
            barcode.scanReason !== null ? barcode.scanReason : ''
          }`,
        );
      } else {
        // exit
        setAdmissionMsg(`${barcode.scanMessage}`);
      }
    }
  }, [barcode]);

  const handleClose = () => {
    setIsShowResult(false);
    setScanCount(0)
    setShowPopup(false)
  };

  return (
    <div className={cName} onClick={onClick} style={style}>
      {admissionMsg && iconClass && (
        <div className={`result-display ${isScanIn !== 'true' && 'exit'}`}>
          <FontAwesomeIcon icon={iconClass} size='5x' />
          <p className='result-display-text'>{admissionMsg}</p>
          {additionalMsg && (
            <p className='result-display-addtext'>{additionalMsg}</p>
          )}
          {voucherMode && 
          <><br />
            <p>{t('validatorEntry_taptoproceed')}</p></>}
          {barcode.scanResult === 1 && barcode.appResponseColor && barcode.appResponseColor !== constants.defaultSuccessColorCode && !isManualEntry ? <button className='close-btn' onClick={handleClose}>
            X
          </button> : null}
        </div>
      )}
    </div>
  );
};

ResultDisplay.propTypes = {
  cName: PropTypes.string.isRequired,
};

export default ResultDisplay;
